<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    this.$store.commit('changeNavbarIndex', 3);
  }
}
</script>

<style lang="scss" scoped>

</style>